jQuery(function($){
	$('[data-toggle="popover"]').popover();
	/**/
	if(is_front_page) {
		var name = "motoit",
    items;
$.get("https://images"+~~(Math.random()*33)+"-focus-opensocial.googleusercontent.com/gadgets/proxy?container=none&url=https://www.instagram.com/" + name + "/", function(html) {
    if (html) {
        var regex = /_sharedData = ({.*);<\/script>/m,
          json = JSON.parse(regex.exec(html)[1]),
          edges = json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges;
      $.each(edges, function(n, edge) {
          var node = edge.node;
          $('.instaAdd').append(
              $('<a/>', {
              href: 'https://instagr.am/p/'+node.shortcode,
              target: '_blank'
          }).css({
              backgroundImage: 'url(' + node.thumbnail_src + ')'
          }));
      });
    }
});			
	}//homepage
	/*
		* Replace all SVG images with inline SVG
	*/
	/*document.querySelectorAll('img.svg').forEach(function(img){
		var imgID = img.id;
		var imgClass = img.className;
		var imgURL = img.src;
		
		fetch(imgURL).then(function(response) {
			return response.text();
			}).then(function(text){
			
			var parser = new DOMParser();
			var xmlDoc = parser.parseFromString(text, "text/xml");
			
			var svg = xmlDoc.getElementsByTagName('svg')[0];
			
			if(typeof imgID !== 'undefined') {
				svg.setAttribute('id', imgID);
			}
			
			if(typeof imgClass !== 'undefined') {
				svg.setAttribute('class', imgClass+' replaced-svg');
			}
			
			svg.removeAttribute('xmlns:a');
			
			if(!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
				svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
			}
			
			img.parentNode.replaceChild(svg, img);
			
		});
	});*/
	//
	$('#navbar-content').on('hide.bs.collapse', function () {
		$('.navbar-toggler').removeClass('open');
	})
	$('#navbar-content').on('show.bs.collapse', function () {
		$('.navbar-toggler').addClass('open');
	})
	//
	myshrink();
	
	$(".Modern-Slider").slick({
		dots:true,
		customPaging : function(slider, i) {
			var thumb = $(slider.$slides[i]).data();
			var textSlidDot = $('.slid_data_'+i).attr('data-mytext');
			//console.log(textSlidDot);
			return '<div class="subDotSlider"><div class="wrapNumDot"><div class="numDot">0'+ (i+1) +'</div></div><div class="progresBarDiv pbardiv'+(i)+'"><span data-slick-index="'+(i+1)+'" class="progressBar"></span></div><div class="titleSlidDot">'+ textSlidDot +'</div></div>';
		},				
		appendDots:$('.appendDotsSlider'),
		arrows:false,
		prevArrow:'<img alt="arrow" class="prevSlid" src="/wp-content/themes/theme/images/slid_left.png" />',
		nextArrow:'<img alt="arrow" class="nextSlid" src="/wp-content/themes/theme/images/slid_right.png" />',		
		autoplay: false,
		autoplaySpeed:9000,
		speed:500,
		/*mobileFirst: true,*/
		slidesToShow:1,
		slidesToScroll:1,
		pauseOnHover:false,
		/*respondTo:'min',*/
		cssEase:'linear',
		//fade:true,
	});
	
	    var percentTime;
    var tick;
    var time = 1;
    var progressBarIndex = 0;
	
    $('.progressBar').each(function(index) {
        var progress = "<div class='inProgress inProgress" + index + "'></div>";
        $(this).html(progress);
	});
	
    function startProgressbar() {
        resetProgressbar();
        percentTime = 0;
        tick = setInterval(interval, 6);
	}
	
    function interval() {
        if (($('.Modern-Slider .slick-track div[data-slick-index="' + progressBarIndex + '"]').attr("aria-hidden")) === "true") {
            progressBarIndex = $('.Modern-Slider .slick-track div[aria-hidden="false"]').data("slickIndex");
            startProgressbar();
			} else {
            percentTime += 1 / (time + 5);
			
			//$('.progresBarDiv').css({'width': '0px', 'overflow': 'hidden'});
            //$('.progresBarDiv.pbardiv' + progressBarIndex).css({'width': '60px', 'outline': '1px solid red'});
			
			//$('.progresBarDiv').addClass('w0ovhid');
			//$('.progresBarDiv.pbardiv' + progressBarIndex).removeClass('w0ovhid');
				
            $('.inProgress' + progressBarIndex).css({
                width: percentTime + "%"
			});
            if (percentTime >= 100) {
                $('.Modern-Slider.single-item').slick('slickNext');
                progressBarIndex++;
				/*
                if (progressBarIndex > 2) {
                    progressBarIndex = 0;
				}
				*/
                startProgressbar();
			}
		}
	}
	
    function resetProgressbar() {
        $('.inProgress').css({
            width: 0 + '%'
		});
        clearInterval(tick);
	}
    startProgressbar();
    // End ticking machine
	
    $('.progresBarDiv').click(function () {
    	clearInterval(tick);
    	var goToThisIndex = $(this).find("span").data("slickIndex");
    	$('.Modern-Slider.single-item').slick('slickGoTo', goToThisIndex - 1, false);
    	startProgressbar();
	});	
	
	//finish	
	
$('.Modern-Slider')
  .on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $('.Modern-Slider .slick-list').addClass('do-transition')
  })
  .on('afterChange', function(){
    $('.Modern-Slider .slick-list').removeClass('do-transition')
  });	
  
  /**/

  $(".ModernProd-Slider").slick({
	dots:false,			
	arrows:false,
	prevArrow:'<img alt="arrow" class="prevSlid" src="/wp-content/themes/theme/images/slid_left.png" />',
	nextArrow:'<img alt="arrow" class="nextSlid" src="/wp-content/themes/theme/images/slid_right.png" />',		
	autoplay: false,
	autoplaySpeed:5000,
	speed:500,
	/*mobileFirst: true,*/
	slidesToShow:1,
	slidesToScroll:1,
	pauseOnHover:false,
	/*respondTo:'min',*/
	cssEase:'linear',
	fade:true,
	asNavFor: '.sliderProdNav',
}); 

$('.sliderProdNav').slick({
	arrows:true,
	prevArrow:'<img alt="arrow" class="prevSlid" src="/wp-content/themes/theme/images/slid_prod_left.svg" />',
	nextArrow:'<img alt="arrow" class="nextSlid" src="/wp-content/themes/theme/images/slid_prod_right.svg" />',	
	slidesToShow: 3,
	slidesToScroll: 1,
	asNavFor: '.ModernProd-Slider',
	centerMode: true,
	centerPadding: '10%',	
	dots: false,
	focusOnSelect: true,
	responsive: [	
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			}
		}
	]	
});	

  /**/
  
  $('.slider-for').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
	prevArrow:'<img alt="arrow" class="prevSlid" src="' + tpl_dir + '/images/slid_sec_left.svg" />',
	nextArrow:'<img alt="arrow" class="nextSlid" src="' + tpl_dir +'/images/slid_sec_right.svg" />',
  fade: false,
  //adaptiveHeight: true,
  asNavFor: '.slider-nav'
});
$('.slider-nav').slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  asNavFor: '.slider-for',
  dots: false,
  arrows:false,
  focusOnSelect: true,
  responsive: [
	{
		breakpoint: 1200,
		settings: {
			slidesToShow: 3,
			slidesToScroll: 1,
		}
	},		
	{
		breakpoint: 768,
		settings: {
			slidesToShow: 2,
			slidesToScroll: 1,
		}
	}
	]
});	 

/**/

$('.galeriaSlickMotoSimple').slick({
	dots: false,
	arrows:true,
	prevArrow:'<div class="simpleArr l"><img alt="arrow" src="' + tpl_dir +'/images/simple_left.svg" /></div>',
	nextArrow:'<div class="simpleArr r"><img alt="arrow" src="' + tpl_dir +'/images/simple_right.svg" /></div>',
	infinite: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
	pauseOnHover: true,
	pauseOnFocus: true,
	
	cssEase: 'ease-out',
	responsive: [
		{
			breakpoint: 1590,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			}
		},		
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			}
		}
	]
});

/**/
	
	$('.galeriaSlick').slick({
		dots: false,
		arrows:false,
		infinite: true,
		slidesToShow: 10,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		pauseOnHover: true,
		pauseOnFocus: true,
		
		cssEase: 'ease-out',
		responsive: [
			{
				breakpoint: 1700,
				settings: {
					slidesToShow: 8,
					slidesToScroll: 1,
				}
			},		
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	});
	
	/**/
	
$(".dealerzy").mouseover(function() {
  $('.dealerzyli, .dealAbsRight').addClass('act');
});
$(".dealerzy").mouseout(function() {
    $('.dealerzyli, .dealAbsRight').removeClass('act');
});
  
  /**/

// detects the start of an ajax request being made
$(document).on("sf:ajaxstart", ".searchandfilter", function(){

	$.LoadingOverlay("show", {
		imageColor : "#fff",
		image: '/wp-content/themes/theme/images/speedometer.svg',
		imageAnimation  : ".5s rotate_right",
		imageClass: 'spinKat',
	});

	});


	// detects the end of an ajax request being made
	$(document).on("sf:ajaxfinish", ".searchandfilter", function(){

	$.LoadingOverlay("hide");
});
/**/ 
	
});

jQuery(window).scroll(function() {
	myshrink();
});

jQuery(window).on("load",function($){
    window.sr = ScrollReveal({ reset: false, mobile: true });
		window.sr = ScrollReveal({ reset: false, mobile: false });
		
		sr.reveal('.colPodSlid', { duration: 500, origin: 'left', distance: '150px'}, 100);
		sr.reveal('.contSecondSlid', { duration: 1000, origin: 'bottom', distance: '150px'});

		sr.reveal('.sekKatHome', { duration: 1000, scale: 0.1});
	
		sr.reveal('.ythaslo1', { duration: 500, origin: 'left', distance: '150px'});
		sr.reveal('.ythaslo2', { duration: 500, origin: 'left', distance: '150px'});
		sr.reveal('.contYtHome .imWrap', { duration: 500, origin: 'left', distance: '150px'});

		sr.reveal('.sekOpisHome .x', { duration: 500, scale: 0.1});
		sr.reveal('.sekOpisHome .o1', { duration: 500, origin: 'left', distance: '150px'});
		sr.reveal('.sekOpisHome .o2', { duration: 500, origin: 'left', distance: '150px'});
		sr.reveal('.sekOpisHome .o3', { duration: 500, origin: 'right', distance: '150px'});

		sr.reveal('.sekBlogHome .t', { duration: 1000, scale: 0.1});

		sr.reveal('.colLeftBanner', { duration: 1000, scale: 0.1});
		sr.reveal('.colRightKont', { duration: 1000, scale: 0.1});
		sr.reveal('.colGaleriaWnetrza', { duration: 1000, scale: 0.1});

		sr.reveal('.sekHist4 .t', { duration: 1000, origin: 'bottom', distance: '150px'});
		sr.reveal('.sekHist4 .j', { duration: 1000, origin: 'bottom', distance: '150px'});
		sr.reveal('.sekHist4 .op', { duration: 1000, origin: 'bottom', distance: '150px'});

		sr.reveal('.sekHist5 .op', { duration: 1000, scale: 0.1});
		sr.reveal('.sekHist5 .wrim', { duration: 1000, scale: 0.1});

		sr.reveal('.sekHist7 p', { duration: 1000, scale: 0.1});
		sr.reveal('.sekHist8 .wrapObr', { duration: 1000, scale: 0.1});
	
	/**/
	
	var s = skrollr.init({
		forceHeight: false
	});
	if (s.isMobile()) {
		s.destroy();
	}
	
	if(true) {
		jQuery('#menu-item-34').addClass('current-menu-item');
		jQuery('.menu-item-34').addClass('current-menu-item');		
	}
});

function myshrink() {
	if (jQuery(document).scrollTop() > 100) {
		jQuery('.oberMenu').addClass('shrink');
		} else {
		jQuery('.oberMenu').removeClass('shrink');
	}	
}

if(is_history_page) {
	jQuery(function($){
		var a = 0;
		$(window).scroll(function() {
		
			var oTop = $('#counter_hist').offset().top - window.innerHeight;
			if (a == 0 && $(window).scrollTop() > oTop) {
				$('.counter-value').each(function() {
		
					var $this = $(this),
				countTo = $this.attr('data-count');
			
					$({
						countNum: $this.text()
					}).animate({
							countNum: countTo
						},
		
						{
		
							duration: 2000,
							easing: 'swing',
							step: function() {
								$this.text(Math.floor(this.countNum));
							},
							complete: function() {
								$this.text(this.countNum);
								//alert('finished');
							}
		
						});
				});
				a = 1;
			}
		
		});
		//
		});
}
